.contribution-graph {
  display: inline-flex;
  flex-direction: column;
  min-width: 730px;
}

/* .contribution-graph.mobile {
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.contribution-graph.mobile::-webkit-scrollbar {
  display: none;
} */

.months {
  display: grid;
  grid-template-columns: repeat(50, 1fr);
  margin-left: 30px;
  margin-bottom: 5px;
}

.month {
  grid-column: span 4;
  text-align: center;
  font-size: 12px;
}

.graph-body {
  display: flex;
}

.white-square {
  position: absolute;
  top: -24px;
  left: 0;
  width: 30px;
  height: 24px;
  background-color: white;
}

.black-square {
  position: absolute;
  top: -24px;
  left: 0;
  width: 30px;
  height: 24px;
  background-color: #100022;
}

.weekdays-container {
  position: sticky;
  left: 0;
  z-index: 2;
}

.weekdays {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 4px;
  height: 91px; /* 7 * 13px (height of day square + gap) */
  position: sticky;
  left: 0;
  z-index: 1;
}

.weekday {
  font-size: 11px;
  height: 12px;
  line-height: 12px;
}

.weeks {
  display: grid;
  grid-template-columns: repeat(53, 1fr);
  gap: 2px;
}

.week {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.day {
  width: 11px;
  height: 11px;
  border-radius: 2px;
}

.contribution-0 {
  background-color: #ebedf0;
}
.contribution-1 {
  background-color: #9be9a8;
}
.contribution-2 {
  background-color: #40c463;
}
.contribution-3 {
  background-color: #30a14e;
}

.contribution-graph .mantine-Tooltip-root {
  display: flex;
}

.contribution-graph .mantine-Tooltip-root .day {
  flex: 1;
}
