.footer {
  margin-top: 120px;
  border-top: 1px solid
    light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--mantine-spacing-md) var(--mantine-spacing-md);

  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
}

.links {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-lg);
    margin-bottom: var(--mantine-spacing-sm);
  }
}
