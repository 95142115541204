@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-8px);
  }
  60% {
    transform: translateX(-50%) translateY(-4px);
  }
}

@keyframes pulse {
  0% {
    opacity: 3;
  }
  50% {
    opacity: 1.5;
  }
  100% {
    opacity: 3;
  }
}
