.feature {
  position: relative;
  padding-top: var(--mantine-spacing-md);
  /* padding-left: var(--mantine-spacing-xl); */
}

.overlay {
  position: absolute;
  height: rem(100px);
  width: rem(160px);
  top: 0;
  left: 0;
  background-color: var(--mantine-color-theme-light);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
}

.icon {
  color: var(--mantine-color-theme-filled);
}

.title {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}
